import { getCountdownEndLabel } from '@local/components/pages/Products/Hero/PurchaseOptions/getCountdownEndLabel';
import { useLocalizer } from '@nintendo-of-america/react-hooks';
import { useRouter } from '@nintendo-of-america/next';
import { useCallback, useMemo } from 'react';

/**
 * Hook to determine if we need to render an invite or sales-related countdown label.
 * @param invite - Invite object
 * @param eshopDetails - eShop API object with sale data
 * @return {*|null} - If the invite is in 'active' or 'expired' state, it will return
 * the appropriate label. If the invite is in another state or if it does not exist, it will return null.
 */
export const usePurchaseCountdown = (invite, eshopDetails) => {
  const { text } = useLocalizer();
  const { locale } = useRouter();

  const getInviteLabel = useCallback(() => {
    if (!invite) {
      return null;
    }
    const { status, expiresAt } = invite;

    switch (status) {
      case 'active':
        return getCountdownEndLabel(
          expiresAt,
          locale,
          text,
          () => {
            window.location.reload();
          },
          'Invite ends: {0}'
        );
      case 'expired':
        return getCountdownEndLabel(
          expiresAt,
          locale,
          text,
          () => {
            window.location.reload();
          },
          'Invite expired: {0}',
          true
        );
    }
  }, [invite, locale, text]);

  return useMemo(() => {
    const inviteLabel = getInviteLabel();

    if (inviteLabel) {
      return inviteLabel;
    }

    return eshopDetails?.discountPriceEnd
      ? getCountdownEndLabel(
          eshopDetails?.discountPriceEnd,
          locale,
          text,
          () => {
            window.location.reload();
          }
        )
      : null;
  }, [eshopDetails?.discountPriceEnd, locale, text, getInviteLabel]);
};
