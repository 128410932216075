import styled from 'styled-components';
import { PriceSpider } from '@local/components';
import {
  QuantitySelector as DSQuantitySelector,
  Link as DSLink,
  Text as DSText,
  Button as CLButton,
  Label,
  Skeleton,
} from '@nintendo-of-america/component-library';

export const QuantitySelector = styled(DSQuantitySelector)`
  height: 100%;
  font-weight: bold;
`;

export const Pricing = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const PriceSkeleton = styled(Skeleton)`
  height: ${({ theme }) => theme.spacing[40]};

  & > div {
    font-size: ${({ theme }) => theme.font.size.h1LMobile};
  }
`;

export const LimitReachedText = styled.span`
  display: ${({ isLoading }) => (isLoading ? 'none' : 'block')};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.color.primary};
`;

export const Link = styled(DSLink)`
  display: inline !important;

  span {
    font-size: ${({ theme }) => theme.font.size.legalMobile};
    ${({ theme }) => theme.mediaQuery.tabletSmall} {
      font-size: ${({ theme }) => theme.font.size.captionMobile};
    }
  }
`;

export const Text = styled(DSText)`
  line-height: 1.5;
`;

export const ErrorText = styled(Text)`
  color: ${({ theme }) => theme.color.primary};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

// TODO: WDEV-644 - remove this after official button text wrapping is added
export const WrappingButton = styled(CLButton)`
  white-space: pre-wrap;
`;

export const StyledStatusLabel = styled(Label)`
  width: max-content;
`;

export const HelperText = styled.span`
  font-weight: ${({ $boldHelperText }) => ($boldHelperText ? 700 : 'initial')};
`;

export const BundleConfigOptions = styled.div`
  margin-top: ${({ theme }) => theme.spacing[4]};
  margin-bottom: ${({ theme }) => theme.spacing[8]};

  ${({ theme }) => theme.mediaQuery.desktop} {
    margin-top: ${({ theme }) => theme.spacing[12]};
  }
`;

export const StyledPriceSpider = styled(PriceSpider)`
  margin-top: var(--theme-spacing-16);

  ${({ theme }) => theme.mediaQuery.desktop} {
    margin-top: var(--theme-spacing-24);
  }
`;

export const Button = styled(CLButton)`
  width: 100%;
`;
