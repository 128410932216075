import React, { useMemo, useState } from 'react';
import {
  BarcodeIcon,
  BuildingIcon,
  CalendarIcon,
  DPadIcon,
  FamilyAccountIcon,
  GearIcon,
  GlobeIcon,
  GroupIcon,
  NsoIcon,
  StorageIcon,
  SwitchLiteIcon,
  SkuIcon,
  Heading,
  Link,
  Spacer,
  Text,
  WrenchIcon,
  TagList,
  SwitchIcon,
  Modal,
  Button,
  GameshareIcon,
} from '@nintendo-of-america/component-library';
import { useLocalizer, useFeature } from '@nintendo-of-america/react-hooks';
import { TOP_LEVEL_CATEGORY_CODE } from '@nintendo-of-america/search';
import { ContentRating } from '@local/components';
import { Grid } from '@shared/ui';
import { getProductTags } from '@shared/util';
import useMediaWidth, { MEDIA_TYPE } from '@local/lib/hooks/useMediaWidth';
import { fileSize, isProductDigital } from '@local/lib/helpers';
import {
  SEARCH_PREFIX,
  getPlayerSupport,
  getProductRomSize,
  getProductTagInfo,
  isProductLendable,
} from './utils';
import * as S from './ProductInfo.styles';
import {
  GAME_SHARE_CODES,
  GAME_SHARE_LABELS,
  PLAY_MODE_CODES,
} from '@local/lib/constants';
import { PLATFORM_CODES } from '@shared/util/constants';

const InfoRow = ({ children, icon, heading, descriptions }) => {
  return (
    <S.InfoRow>
      {icon}
      <Heading variant="h3">{heading}</Heading>
      <div>
        {!!descriptions?.length && (
          <S.InfoDescr>
            {descriptions?.map((descr) => (
              <div key={descr.label}>
                {descr.href ? (
                  <Link href={descr.href}>{descr.label}</Link>
                ) : (
                  descr.label
                )}
              </div>
            ))}
          </S.InfoDescr>
        )}
        {children}
      </div>
    </S.InfoRow>
  );
};

const nsoUrl = '/switch/online/';
const baseSwitchPLPUrl =
  '/store/games/#p=1&sort=df&show=0&f=corePlatforms&corePlatforms=Nintendo+Switch';

const ProductInfo = ({ product }) => {
  const { date, DateFormat, text, locale } = useLocalizer();
  const romSize = getProductRomSize(product);
  const isLendable = isProductLendable(product);
  const [isSystemModalOpen, setIsSystemModalOpen] = useState(false);

  const isDesktop = useMediaWidth(MEDIA_TYPE.DESKTOP);

  const enableTagLists = useFeature('WDEV-4622-tags');
  const enableTagsOnPDP =
    !!enableTagLists?.variant?.payload?.value?.enableOnPDP;
  const enableTagsOnPLP =
    !!enableTagLists?.variant?.payload?.value?.enableOnPLP;

  const productTagInfo = useMemo(() => getProductTagInfo(product), [product]);
  const productTags = useMemo(() => getProductTags(product), [product]);

  const genres = product.genres?.map((genre) => ({
    href: `${SEARCH_PREFIX}/#cat=gme&f=genres&genres=${encodeURIComponent(
      genre.label
    )}`,
    label: genre.label,
  }));

  const gameGenres = productTags.gameGenres?.map((genre) => ({
    href: `${SEARCH_PREFIX}/#cat=gme&f=gameGenreLabels&gameGenreLabels=${encodeURIComponent(
      genre.label
    )}`,
    label: genre.label,
  }));

  const { playModes, gameShare } = (product.playModes || []).reduce(
    ({ playModes, gameShare }, playMode) => {
      return GAME_SHARE_CODES[playMode.code]
        ? {
            playModes,
            gameShare: [...gameShare, text(GAME_SHARE_LABELS[playMode.label])],
          }
        : PLAY_MODE_CODES[playMode.code]
          ? {
              gameShare,
              playModes: [...playModes, text(playMode.label)],
            }
          : { playModes, gameShare };
    },
    { playModes: [], gameShare: [] }
  );

  const systemUrl = `${baseSwitchPLPUrl}${product?.platform?.code === PLATFORM_CODES.NINTENDO_SWITCH_2 ? '+2' : ''}`;

  const playerSupport = getPlayerSupport(product, text);
  const isDigital = isProductDigital(product);

  const isHardware =
    product?.topLevelCategory?.code === TOP_LEVEL_CATEGORY_CODE.HARDWARE;

  return (
    <>
      {enableTagsOnPDP && productTagInfo.length > 0 && (
        <S.TagContainer>
          <TagList
            sectionTitle={text('Related tags')}
            tags={productTagInfo}
            showMoreLabel={text('Show more')}
            showLessLabel={text('Show less')}
            defaultRowCount={isDesktop ? 2 : 3}
          />
        </S.TagContainer>
      )}
      <S.InfoSection>
        <S.HeadingSection>
          {/* TODO: Add i18n / Ticket TBD */}
          <Heading variant="h1">{text('About this item')}</Heading>
        </S.HeadingSection>
        <Heading.NewLevel>
          <Grid columns={1} gap={0}>
            {romSize && (
              <InfoRow
                icon={<StorageIcon size={28} />}
                heading={text('Game file size')}
                descriptions={[{ label: fileSize(romSize) }]}
              />
            )}
            {product.playModes && (
              <InfoRow
                icon={<SwitchLiteIcon size={28} />}
                heading={text('Supported play modes')}
                descriptions={[{ label: playModes.join(', ') }]}
              />
            )}
            {product.playersMax && (
              <InfoRow
                icon={<GroupIcon size={28} />}
                heading={text('No. of players')}
                descriptions={playerSupport}
              />
            )}
            {gameShare?.length > 0 && (
              <InfoRow
                icon={<GameshareIcon size={28} />}
                heading="GameShare"
                descriptions={[{ label: gameShare.join(', ') }]}
              >
                {gameShare.includes(text('Online')) && (
                  <>
                    <Spacer size={12} />
                    <Text variant="legal">
                      {text(
                        'Nintendo Switch 2 required to initiate GameShare. GameChat required for online GameShare. Internet, Nintendo Switch Online membership (sold separately) and Nintendo Account required for online features, including GameChat. Not available in all countries. Terms and GameChat requirements apply.'
                      )}{' '}
                      <Link
                        key="gameshare-url"
                        href={text('https://en-americas-support.nintendo.com/')}
                      >
                        {text('support.nintendo.com')}
                      </Link>
                    </Text>
                  </>
                )}
              </InfoRow>
            )}
            {/* TODO: Update this logic in MOSS-524 */}
            {false && isLendable && (
              <InfoRow
                icon={<FamilyAccountIcon size={28} />}
                heading={text('Nintendo Account Family Share')}
                descriptions={[{ label: text('Supported') }]}
              />
            )}
            {enableTagsOnPLP && product.gameGenres?.length > 0 && (
              <InfoRow
                icon={<DPadIcon size={28} />}
                heading={text('Genre')}
                descriptions={gameGenres}
              />
            )}
            {!enableTagsOnPLP && product.genres && (
              <InfoRow
                icon={<DPadIcon size={28} />}
                heading={text('Genre')}
                descriptions={genres}
              />
            )}
            {product.nsoFeatures && (
              <InfoRow
                icon={<NsoIcon size={28} />}
                heading={text('Nintendo Switch Online')}
                descriptions={product.nsoFeatures.map(({ label }) => ({
                  href: nsoUrl,
                  label: text(label),
                }))}
              >
                <Spacer size={12} />
                <Text variant="legal">
                  {text(
                    'Play online, access classic NES™ and Super NES™ games, and more with a Nintendo Switch Online membership.'
                  )}{' '}
                  <Link key="nso-url" href={nsoUrl}>
                    {text('Learn more')}
                  </Link>
                </Text>
              </InfoRow>
            )}
            {product.platform?.label && (
              <InfoRow
                icon={<SwitchIcon size={28} />}
                heading={text('System')}
                descriptions={[
                  {
                    href: systemUrl,
                    label: product.platform.label,
                  },
                ]}
              >
                <Spacer size={12} />
                <Text variant="legal">
                  {text('Which system(s) will play this game?')}
                  <Link
                    key="systems-modal"
                    onClick={() => {
                      setIsSystemModalOpen(true);
                    }}
                  >
                    {text('Learn more')}
                  </Link>
                </Text>
              </InfoRow>
            )}
            {product.softwarePublisher && (
              <InfoRow
                icon={<BuildingIcon size={28} />}
                heading={text('Publisher')}
                descriptions={[
                  {
                    href: `${SEARCH_PREFIX}#cat=gme&f=softwarePublisher&softwarePublisher=${encodeURIComponent(
                      product.softwarePublisher
                    )}`,
                    label: product.softwarePublisher,
                  },
                ]}
              />
            )}
            {product.softwareDeveloper && (
              <InfoRow
                icon={<WrenchIcon size={28} />}
                heading={text('Developer')}
                descriptions={[
                  {
                    href: `${SEARCH_PREFIX}#cat=gme&f=softwareDeveloper&softwareDeveloper=${encodeURIComponent(
                      product.softwareDeveloper
                    )}`,
                    label: product.softwareDeveloper,
                  },
                ]}
              />
            )}
            {product.supportedLanguages && (
              <InfoRow
                icon={<GlobeIcon size={28} />}
                heading={text('Supported languages')}
                descriptions={[
                  {
                    label: product.supportedLanguages?.map(text).join(', '),
                  },
                ]}
              />
            )}
            {product.releaseDate && (
              <InfoRow
                icon={<CalendarIcon size={28} />}
                heading={text('Release date')}
                descriptions={[
                  {
                    label: product.releaseDateDisplay
                      ? product.releaseDateDisplay
                      : date(product.releaseDate, { format: DateFormat.LONG }),
                  },
                ]}
              />
            )}
            {product.contentRating != null && (
              <InfoRow
                icon={<GearIcon size={28} />}
                heading={text('ESRB rating')}
              >
                <Spacer size={12} />
                <ContentRating
                  descriptors={product.contentDescriptors}
                  rating={product.contentRating}
                  locale={locale}
                />
              </InfoRow>
            )}
            {product.countryOfOrigin && (
              <InfoRow
                icon={<GlobeIcon size={28} />}
                heading={text('Country of origin')}
                descriptions={[{ label: product.countryOfOrigin }]}
              />
            )}
            {product.manufacturer && (
              <InfoRow
                icon={<BuildingIcon size={28} />}
                heading={text('Manufacturer')}
                descriptions={[
                  {
                    href: `${SEARCH_PREFIX}#cat=${isHardware ? 'hdw' : 'merch'}&f=manufacturer&manufacturer=${encodeURIComponent(
                      product.manufacturer
                    )}&p=1&sort=df`,
                    label: product.manufacturer,
                  },
                ]}
              />
            )}
            {product.sku && !isDigital && (
              <InfoRow
                icon={<SkuIcon size={28} />}
                heading={text('SKU')}
                descriptions={[{ label: product.sku }]}
              />
            )}
            {product.upc && !product.nsuid && (
              <InfoRow
                icon={<BarcodeIcon size={28} />}
                heading={text('UPC')}
                descriptions={[{ label: product.upc }]}
              />
            )}
          </Grid>
        </Heading.NewLevel>
        <Modal
          visible={isSystemModalOpen}
          onClose={() => setIsSystemModalOpen(false)}
          dialogLabel={text('Which system(s) will play this game?')}
          title={text('Which system(s) will play this game?')}
        >
          <S.ModalBody>
            <div>
              <Heading variant="h4">Nintendo Switch</Heading>
              <Spacer size={2} />
              <Text>
                {text(
                  'You can play this game on Nintendo Switch, Nintendo Switch Lite, Nintendo Switch – OLED Model, and Nintendo Switch 2 systems*.'
                )}
              </Text>
              <Spacer size={12} />
              <Text variant="legal">
                {text(
                  '*Some Nintendo Switch games may not be supported or fully compatible with Nintendo Switch 2. {0}',
                  {
                    args: [
                      <Link
                        key="learn-more"
                        href="/gaming-systems/switch-2/transfer-guide/compatible-games/"
                      >
                        {text('Learn more')}
                      </Link>,
                    ],
                  }
                )}
              </Text>
            </div>
            <div>
              <Heading variant="h4">Nintendo Switch 2</Heading>
              <Spacer size={2} />
              <Text>
                {text(
                  'You can play this game exclusively on Nintendo Switch 2.'
                )}
              </Text>
            </div>
            <div style={{ textAlign: 'center' }}>
              <Button onClick={() => setIsSystemModalOpen(false)}>
                {text('Close')}
              </Button>
            </div>
          </S.ModalBody>
        </Modal>
      </S.InfoSection>
    </>
  );
};

export default ProductInfo;
