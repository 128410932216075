import {
  ARGENTINA_LOCALE,
  CANADA_EN_LOCALE,
  CANADA_FR_LOCALE,
  DEFAULT_LOCALE,
  BRAZIL_LOCALE,
  MEXICO_LOCALE,
} from '@shared/util/constants';
import formatLocale from '@local/lib/helpers/formatLocale';
import { CountdownTimer } from '@nintendo-of-america/component-library';

// Handle specific timezone cases that `toLocaleTimeString` does not handle
// like the localization team wants
const formatTz = (time, locale) => {
  const utcRegex = /(?:UTC|GMT).(\d)/;
  const localesToReplace = [
    CANADA_FR_LOCALE,
    MEXICO_LOCALE,
    ARGENTINA_LOCALE,
    BRAZIL_LOCALE,
  ];
  const tzs = {
    [CANADA_FR_LOCALE]: {
      4: 'HNA',
    },
    [MEXICO_LOCALE]: {
      8: 'PSTM',
      7: 'MSTM',
      6: 'CSTM',
      5: 'ESTM',
    },
    [ARGENTINA_LOCALE]: {
      3: 'ART',
    },
    [BRAZIL_LOCALE]: {
      2: 'FNT',
      3: 'BRT',
      4: 'AMT',
      5: 'ACT',
    },
  };

  return localesToReplace.includes(locale)
    ? time.replace(utcRegex, (tz, offset) => tzs[locale][offset] || tz)
    : time;
};

/**
 * Utility function to display a label which turns into a countdown if < 24 hours.
 *
 * @param countdownEnd - The time when a specific event ends (e.g. sale end time)
 * @param locale - Language locale the label should be displayed in
 * @param text - Text function for localization
 * @param onCountdownEnd - onEnd function to kick off
 * @param labelPrefix - Label to be displayed. Defaults to 'Sale ends: {0}'
 * @param forceDisplayExpiredDate - Boolean value to force display a label for expired dates. Defaults to false.
 * @return {*|null}
 */
export const getCountdownEndLabel = (
  countdownEnd,
  locale,
  text,
  onCountdownEnd,
  labelPrefix = 'Sale ends:',
  forceDisplayExpiredDate = false
) => {
  const currentDate = new Date();
  const endDate = new Date(countdownEnd);
  const isFrenchCanada = locale === CANADA_FR_LOCALE;
  const diffMs = endDate.getTime() - currentDate.getTime();
  const hoursLeft = diffMs / 60000 / 60;

  if (diffMs < 0 && !forceDisplayExpiredDate) {
    return null;
  }

  if (hoursLeft > 24 || forceDisplayExpiredDate) {
    const dateLocale = isFrenchCanada
      ? // This will render the date in DD/MM/YYYY format as fr-CA natively
        // formats it as YYYY/DD/MM, which isn't desired
        'es-MX'
      : locale === CANADA_EN_LOCALE
        ? 'en-US'
        : formatLocale(locale);

    try {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const date = endDate.toLocaleDateString(dateLocale, {
        timeZone,
        day: 'numeric',
        month: 'numeric',
        year: '2-digit',
        // fr-ca has some specific rules from the translation team that need to
        // overwrite how toLocaleDateString natively handles it
        ...(isFrenchCanada && {
          month: '2-digit',
          day: '2-digit',
        }),
      });

      // pt-br also has specific rules from the translation team that go
      // against native javascript implementation
      const timeLocale =
        locale === BRAZIL_LOCALE ? 'fr-CA' : formatLocale(locale);

      const time = endDate.toLocaleTimeString(timeLocale, {
        timeZone,
        timeZoneName: 'short',
        hour: '2-digit',
        minute: '2-digit',
        // Only enligh uses a.m./p.m. format
        hour12: [CANADA_EN_LOCALE, DEFAULT_LOCALE].includes(locale),
      });

      return text(`${labelPrefix} {0} at {1}`, {
        args: [
          `${isFrenchCanada ? date.replace(/\//g, '-') : date}`,
          formatTz(
            time.replace(/(A|P){1}M/, (match, p1) => `${p1.toLowerCase()}.m.`),
            locale
          ),
        ],
      });
    } catch {
      return null;
    }
  } else {
    return text(`${labelPrefix} {0}`, {
      args: [
        <CountdownTimer
          key="timer"
          onTimeEnd={onCountdownEnd}
          endDate={countdownEnd}
          abbreviate={false}
        />,
      ],
    });
  }
};
