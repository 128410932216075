import { MegaphoneDarkIcon } from '@nintendo-of-america/component-library';
import { useLocalizer } from '@nintendo-of-america/react-hooks';
import s from './SystemCallout.module.css';
import RichText from '@local/components/RichText';

const SystemCallout = ({ callout }) => {
  const { text } = useLocalizer();

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <MegaphoneDarkIcon className={s.icon} />
        <span className={s.title}>{text('Nintendo Switch 2 features')}</span>
      </div>
      <RichText data={callout} />
    </div>
  );
};

export default SystemCallout;
