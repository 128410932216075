import React from 'react';
import {
  Image,
  Text,
  defaultTheme as theme,
} from '@nintendo-of-america/component-library';
import { useLocalizer } from '@nintendo-of-america/react-hooks';
import { getDefaultVariation, isDlc } from '@shared/util';
import { PLATFORM_CODES } from '@shared/util/constants';
import { Link } from '@nintendo-of-america/next';
import { Grid, GridItem } from '@shared/ui';
import s from './RequiredToPlay.module.css';

const RequiredToPlay = (props) => {
  const { product } = props;
  const { text } = useLocalizer();

  const { baseSoftware, variations, isUpgrade } = product;

  const requiredProduct = isDlc(product)
    ? baseSoftware?.[0]
    : isUpgrade
      ? getDefaultVariation(PLATFORM_CODES.NINTENDO_SWITCH, variations)?.product
      : null;

  if (!requiredProduct) return null;

  const { name, productImage, urlKey } = requiredProduct;

  return (
    <Link href={urlKey}>
      <a>
        <Grid columns="1fr 4fr" gap={theme.spacing[8]} alignItems="center">
          <GridItem column="span 2">
            <Text className={s.text} variant="legal">
              {text('Required to play:')}
            </Text>
          </GridItem>
          <Image
            className={s.image}
            assetPath={productImage?.publicId}
            alt={name}
          />
          <Text className={s.text} variant="legal">
            {name}
          </Text>
        </Grid>
      </a>
    </Link>
  );
};

export default RequiredToPlay;
