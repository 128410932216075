import clsx from 'clsx';
import {
  Cap,
  KeyArt,
  Link,
  Modal,
} from '@nintendo-of-america/component-library';
import { useLocalizer } from '@nintendo-of-america/react-hooks';
import s from './UpgradeProductModal.module.css';

const UpgradeProductModal = (props) => {
  const { visible, onClose, productImage, productImageSquare, productName } =
    props;
  const { text } = useLocalizer();

  return (
    <Modal
      dialogLabel={text('Upgrade Pack')}
      visible={visible}
      onClose={onClose}
      className={s.modal}
    >
      <div className={s.heading}>
        <div className={s.headingText}>{text("What's an upgrade pack?")}</div>
      </div>
      <div className={s.subheading}>
        <div className={s.subheadingTitle}>
          {text(
            'Do you have a physical or digital version of this game for Nintendo Switch?'
          )}
        </div>
        <div className={s.subheadingText}>
          {text(
            'You can play the Nintendo Switch 2 Edition by purchasing an upgrade pack.'
          )}
        </div>
      </div>
      <div className={s.graphic}>
        <div className={clsx(s.graphicItem, s.ownedItem)}>
          <KeyArt
            assetPath={productImageSquare?.url}
            alt={productName}
            className={clsx(s.keyArt, s.borderRadius)}
          />
          <p className={s.subtext}>{text('You have this!')}</p>
        </div>
        <div className={s.symbol}>+</div>
        <div className={s.graphicItem}>
          <div className={s.borderRadius}>
            <Cap show />
            <KeyArt
              blurBackground
              assetPath={productImage?.url}
              alt={productName}
              className={s.keyArt}
            />
            <div className={s.tag}>{text('Upgrade pack')}</div>
          </div>
          <p className={s.subtext}>{text('Upgrade pack')}</p>
        </div>
        <div className={s.symbol}>=</div>
        <div className={s.graphicItem}>
          <div className={s.borderRadius}>
            <Cap show />
            <KeyArt
              assetPath={productImageSquare?.url}
              alt={productName}
              className={s.keyArt}
            />
          </div>
          <p className={s.subtext}>{text('Nintendo Switch 2 Edition')}</p>
        </div>
      </div>
      <div className={s.footer}>
        <Link href="/gaming-systems/switch-2/featured-games/switch-2-edition/">
          {text('Learn more about Nintendo Switch 2 Editions')}
        </Link>
      </div>
    </Modal>
  );
};

export default UpgradeProductModal;
