import { useMemo } from 'react';
import { useRouter } from '@nintendo-of-america/next';
import { useLocalizer } from '@nintendo-of-america/react-hooks';
import * as S from './OptionDisplays.styles';

function SelectDisplay({ variations, loadingPricing }) {
  const router = useRouter();
  const { text, price } = useLocalizer();

  const options = useMemo(() => {
    return variations.map((variation) => ({
      label: variation.product.nsuid ? text('Digital') : text('Physical'),
      caption: variation.product.name,
      price: loadingPricing
        ? null
        : price(variation.product?.prices?.minimum?.finalPrice?.toFixed(2)),
      value: variation.product.name,
      urlKey: variation.product.urlKey,
    }));
  }, [variations, text, loadingPricing, price]);

  const selectedOption = useMemo(() => {
    return (
      options.find(({ urlKey }) => router.query.slug === urlKey) || options[0]
    );
  }, [options, router]);

  return (
    <S.StyledSelectDetailed
      style={{ zIndex: '100' }}
      inputName="select-an-edition"
      inputLabel={
        <S.InputLabel variant="caption">
          {text('Select an edition')}
        </S.InputLabel>
      }
      onChange={(e) => {
        router.push(e.urlKey, null, { scroll: false });
      }}
      options={options}
      value={selectedOption}
      showErrors={false}
    />
  );
}

export default SelectDisplay;
