import styled, { css } from 'styled-components';

export const HeadingSection = styled.div`
  padding: var(--theme-spacing-16);
  padding-top: 0;

  ${({ theme }) => theme.mediaQuery.tablet} {
    padding: var(--theme-spacing-16);
  }
`;

export const InfoSection = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[32]};
  margin-left: calc(-1 * var(--theme-spacing-16));
  margin-right: calc(-1 * var(--theme-spacing-16));

  ${({ theme }) => theme.mediaQuery.tablet} {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const InfoRow = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 28px 1fr;
    align-items: start;
    gap: ${({ theme }) => theme.spacing[20]};
    grid-row-gap: 0;
    border-bottom: 1px solid ${({ theme }) => theme.color.lightGray2};
    padding: var(--theme-spacing-24) var(--theme-spacing-16);

    svg {
      grid-row: 1 / 3;
    }

    &:nth-child(1) {
      border-top: 1px solid ${({ theme }) => theme.color.lightGray2};
    }

    ${theme.mediaQuery.tablet} {
      grid-template-columns: 28px 1fr 2fr;
      align-items: center;

      svg {
        grid-row: 1;
      }
    }
  `}
`;

export const InfoDescr = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--theme-spacing-20);
  font-size: var(--theme-font-size-bodyMobile);
  padding: 6px 0 0;

  button {
    padding: 0;
  }

  ${({ theme }) => theme.mediaQuery.tablet} {
    font-size: var(--theme-font-size-bodyDesktop);
    gap: ${({ theme }) => theme.spacing[16]};
  }
`;

export const PlayModeIcon = styled.div`
  ${({ theme }) => css`
    background: ${(props) =>
      props.supported ? theme.color.secondary : 'rgba(60, 64, 89, 0.15)'};
    color: ${(props) => (props.supported ? '#fff' : '#c8c8c8')};
  `}
  padding: ${({ theme }) => theme.spacing[16]};
  display: grid;
  justify-content: center;
  box-shadow: 0 4px 16px 0 rgba(60, 64, 89, 0.15);
  border-radius: ${({ theme }) => theme.borderRadius};
`;

export const Test = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #e60012;
  width: 100%;
  padding: 1em ${({ theme }) => theme.spacing[32]};
`;

export const TagContainer = styled.div`
  margin: ${({ theme }) => theme.spacing[24]} 0;

  ${({ theme }) => theme.mediaQuery.tablet} {
    margin: ${({ theme }) => theme.spacing[40]} 0;
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[24]};
  max-width: 500px;
  padding: ${({ theme }) => theme.spacing[16]};

  ${({ theme }) => theme.mediaQuery.tablet} {
    padding: ${({ theme }) => theme.spacing[24]}
      ${({ theme }) => theme.spacing[32]};
  }
`;
