import { useState } from 'react';
import { arrayOf, bool, shape, string } from 'prop-types';
import { PlatformButtonGroup } from '@nintendo-of-america/component-library';
import { useLocalizer } from '@nintendo-of-america/react-hooks';
import { PLATFORM_CODES } from '@shared/util/constants';
import { getDefaultVariation } from '@shared/util';
import s from './VersionOptions.module.css';
import UpgradeProductModal from './UpgradeProductModal';
import { TypeLabel } from '@shared/ui';

export default function VersionOptions({ platformLabel, product }) {
  const { text } = useLocalizer();
  const [upgradeModalVisible, setUpgradeModalVisible] = useState(false);
  if (!product) {
    return null;
  }
  const { variations, isUpgrade, productImage, productImageSquare, name } =
    product;
  if (!variations) {
    return null;
  }

  const handleClose = () => {
    setUpgradeModalVisible(false);
  };

  const options = [
    PLATFORM_CODES.NINTENDO_SWITCH_2,
    PLATFORM_CODES.NINTENDO_SWITCH,
  ].reduce((options, platformCode) => {
    const variation = getDefaultVariation(platformCode, variations);
    return variation
      ? [
          ...options,
          {
            label: variation.product.platform.label,
            href: variation.product.url,
          },
        ]
      : options;
  }, []);

  const upgradeOption = variations.find((v) => v.product.isUpgrade);

  if (upgradeOption) {
    options.push({
      label: text('Upgrade Pack'),
      href: upgradeOption.product.url,
      isUpgrade: true,
    });
  }

  return (
    <div className={s.versionOptions}>
      {options.length === 1 ? (
        <TypeLabel label={text('Version')} value={options[0].label} />
      ) : (
        <PlatformButtonGroup
          heading={text('Select a version')}
          platformLabel={isUpgrade ? text('Upgrade Pack') : platformLabel}
          options={options}
        />
      )}
      {upgradeOption && (
        <div className={s.upgradeSection}>
          <button
            className={s.upgradeLabel}
            onClick={() => setUpgradeModalVisible(true)}
          >
            {text("What's an upgrade pack?")}
          </button>
          <UpgradeProductModal
            visible={upgradeModalVisible}
            onClose={handleClose}
            productImage={productImage}
            productImageSquare={productImageSquare}
            productName={name}
          />
        </div>
      )}
    </div>
  );
}

VersionOptions.propTypes = {
  platformLabel: string,
  product: shape({
    variations: arrayOf(
      shape({
        product: shape({
          platform: shape({
            label: string,
          }),
          url: string,
        }),
      })
    ),
    isUpgrade: bool,
  }),
};
