import PropTypes from 'prop-types';
import { TypeLabel } from '@shared/ui';
import { useLocalizer } from '@nintendo-of-america/react-hooks';
import { getVariationsByPlatformCode } from '@shared/util';
import { SelectDisplay, SwatchDisplay, MobileDisplay } from './OptionDisplays';

const ProductSelector = ({ onChange, isBundle, product, title, value }) => {
  const { topLevelCategory, variations, nsuid, isUpgrade, loadingPricing } =
    product;
  const { text } = useLocalizer();

  const platformVariations = getVariationsByPlatformCode(
    variations,
    product?.platform?.code
  );

  if (topLevelCategory?.code === 'GAMES') {
    if (nsuid == 'MOBILE') {
      return <MobileDisplay product={product} />;
    } else if (platformVariations.length === 1 || isUpgrade) {
      return (
        <TypeLabel
          label={text('Edition')}
          value={nsuid ? text('Digital') : text('Physical')}
        />
      );
    } else if (platformVariations?.length >= 2) {
      return (
        <SelectDisplay
          variations={platformVariations}
          loadingPricing={loadingPricing}
        />
      );
    }
  }

  if (variations?.length > 0) {
    return (
      <SwatchDisplay
        isBundle={isBundle}
        onChange={onChange}
        product={product}
        title={title}
        value={value}
      />
    );
  }

  return null;
};

ProductSelector.propTypes = {
  onChange: PropTypes.func,
  product: PropTypes.shape({
    topLevelCategory: PropTypes.shape({ code: PropTypes.string }),
    nsuid: PropTypes.string,
    variations: PropTypes.arrayOf(
      PropTypes.shape({
        product: PropTypes.shape({
          nsuid: PropTypes.string,
          name: PropTypes.string,
          urlKey: PropTypes.string,
          prices: PropTypes.shape({
            minimum: PropTypes.shape({ finalPrice: PropTypes.number }),
          }),
        }),
      })
    ),
  }),
  value: PropTypes.string,
};

export default ProductSelector;
