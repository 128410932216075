import styled, { css } from 'styled-components';
import { RichText, Section } from '@local/components';

export const ReadMoreSection = styled(Section)`
  margin-top: ${({ theme }) => theme.spacing[32]};
  ${({ theme }) => theme.mediaQuery.tablet} {
    margin-top: ${({ theme }) => theme.spacing[64]};
  }
`;

export const legal = styled.div`
  p,
  a {
    ${({ theme }) => css`
      font-size: ${theme.font.size.legalMobile};
      ${theme.mediaQuery.tablet} {
        font-size: ${theme.font.size.legalDesktop};
      }
    `}
  }
`;

export const PropSixFive = styled.div`
  display: flex;
  align-items: baseline;
  gap: 0.25rem;
`;

export const DescrImageContainer = styled.div`
  display: block;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borderRadius};
  overflow: hidden;

  img {
    max-height: 24em;
    object-fit: contain;
  }
`;

export const Grid = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr;
    align-items: start;
    gap: ${({ theme }) => theme.spacing[28]};

    ${theme.mediaQuery.tablet} {
      grid-template-columns: repeat(2, 1fr);
    }
  `}
`;

export const PublisherNotifications = styled.div`
  ${({ theme }) => css`
    background: ${theme.color.lightGray4};
    padding: ${theme.spacing[32]} ${theme.spacing[24]};
    ${theme.mediaQuery.tablet} {
      padding: ${theme.spacing[32]} ${theme.spacing[48]};
    }

    ul {
      padding-inline-start: 20px;
    }
  `}
`;

export const AgeGateLoadingContainer = styled.section`
  padding: 1rem;
`;

export const BannerSection = styled(Section)`
  margin-top: ${({ theme }) => theme.spacing[24]};
`;

export const MiscWrap = styled.div`
  margin-top: ${({ theme }) => theme.spacing[40]};

  & > div:not(:last-of-type) {
    margin-bottom: ${({ theme }) => theme.spacing[16]};
  }

  ${({ theme }) => theme.mediaQuery.tablet} {
    margin-top: ${({ theme }) => theme.spacing[80]};
  }
`;

export const MiscText = styled(RichText)`
  &,
  & > p {
    margin-top: 0;
    font-size: ${({ theme }) => theme.font.size.captionMobile};

    ${({ theme }) => theme.mediaQuery.tabletSmall} {
      font-size: ${({ theme }) => theme.font.size.captionDesktop};
    }
  }
`;
