import styled from 'styled-components';
import {
  SelectDetailed,
  QuantitySelector,
  RadioDetailed,
  Text,
} from '@nintendo-of-america/component-library';

export const StyledRadioDetailed = styled(RadioDetailed)`
  margin-bottom: ${({ theme }) => theme.spacing[8]};
`;

export const StyledSelectDetailed = styled(SelectDetailed)`
  z-index: 2;
`;

export const StyledQuantitySelector = styled(QuantitySelector)`
  width: 100%;
`;

export const StyledText = styled(Text)`
  margin-bottom: ${({ theme }) => theme.spacing[8]};
`;

export const InputLabel = styled(Text)`
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;
