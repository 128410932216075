import styled, { css } from 'styled-components';
import { IconLink, Image } from '@nintendo-of-america/component-library';
import { Constrained } from '@local/components';

export const HeroSection = styled.section`
  background-color: ${(props) =>
    props.$bgColor ? props.$bgColor : ({ theme }) => theme.color.lightGray4};
  position: relative;
  z-index: 5; /* Allows box-shadow to appear above a Section with a background below it */
`;

export const Hero = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: ${({ theme }) => theme.spacing[16]};
    grid-row-gap: ${({ theme }) => theme.spacing[16]};

    ${theme.mediaQuery.tablet} {
      grid-template-columns: 3fr 2fr;
      grid-column-gap: ${({ theme }) => theme.spacing[48]};
    }
  `}
  background: #fff;
  max-width: 1240px;
  margin: 0 auto;
  transform: translateY(2em);
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: 0 4px 16px 0 rgba(60, 64, 89, 0.15);

  @media (max-width: 1240px) {
    transform: translateY(0);
    border-radius: 0;
  }
  ${({ theme }) => theme.mediaQuery.tablet} {
    padding: ${({ theme }) => theme.spacing[32]}
      ${({ theme }) => theme.spacing[48]};
  }
`;

export const GallerySide = styled.div`
  overflow: hidden;
`;

export const ChokingHazard = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BreadCrumbContainer = styled.div`
  padding-top: ${({ theme }) => theme.spacing[16]};
  padding-left: ${({ theme }) => theme.spacing[16]};
  overflow: hidden;
  grid-column: 1;
  ${({ theme }) => theme.mediaQuery.tablet} {
    grid-column: span 2;
    padding: 0;
  }
`;

export const StyledIconLink = styled(IconLink)`
  display: flex;
  margin: 0;
  padding: 0;
  border: none;
`;

export const ExtraLinks = styled.div`
  display: flex;
  justify-content: left;
  gap: ${({ theme }) => theme.spacing[16]};
  font-size: var(--theme-font-size-bodyMobile);

  ${({ theme }) => theme.mediaQuery.tablet} {
    font-size: var(--theme-font-size-bodyDesktop);
  }
`;

export const MediaGalleryWrapper = styled(Constrained)`
  ${({ theme }) => css`
    margin-left: 0;
    margin-right: 0;
    ${theme.mediaQuery.tablet} {
      width: 100%;
    }
  `}
`;

export const Warnings = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.spacing[8]};
    align-items: center;
    flex-wrap: wrap;
    padding: 0 ${theme.spacing[16]};
    ${theme.mediaQuery.tablet} {
      padding: 0 0 ${theme.spacing[16]} 0;
    }
  `}
`;

export const HeroInfo = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    padding: 0 ${theme.spacing[16]} ${theme.spacing[16]} ${theme.spacing[16]};

    a {
      text-decoration: none;
    }
    ${theme.mediaQuery.tablet} {
      padding: 0;
    }
  `}
`;

export const WarningImg = styled(Image)`
  max-width: 215px;
`;

export const ShowDesktop = styled.div`
  display: none;
  ${({ theme }) => theme.mediaQuery.tablet} {
    display: block;
  }
`;

export const ShowMobile = styled.div`
  display: block;
  ${({ theme }) => theme.mediaQuery.tablet} {
    display: none;
  }
`;

export const PlatformContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[16]};
`;

export const Tag = styled.span`
  display: none;
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.white};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: ${({ theme }) => theme.font.size.bodyDesktop};
  align-items: center;
  padding: 0 ${({ theme }) => theme.spacing[16]};
  border-radius: ${({ theme }) => theme.borderRadius};
  margin-bottom: ${({ theme }) => theme.spacing[8]};
  width: fit-content;
  margin-top: ${({ theme }) => theme.spacing[32]};

  ${({ theme }) => theme.mediaQuery.tablet} {
    display: block;
  }
`;
