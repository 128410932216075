import { useMemo, useCallback, useEffect, useState } from 'react';
import { useRouter } from '@nintendo-of-america/next';
import { useApolloClient } from '@apollo/client';
import {
  useCart,
  useLocalizer,
  useAccounts,
  useCustomer,
  useMaintenance,
  useCookies,
  ConsentGroups,
  useAnalytics,
} from '@nintendo-of-america/react-hooks';
import { useAnalytics as useSearchAnalytics } from '@nintendo-of-america/search';
import * as S from '../../components/pages/Products/Hero/PurchaseOptions/PurchaseOptions.styles';
import { isProductDigital } from '@local/lib/helpers';

const usePurchaseOptions = (product) => {
  const { locale } = useRouter();
  const apolloClient = useApolloClient();
  const maintenance = useMaintenance();
  const account = useAccounts();
  const cart = useCart();
  const { trackAddToCart } = useAnalytics();
  const customer = useCustomer();
  const { text, price, date } = useLocalizer();

  const {
    __typename,
    availability,
    bundleItems,
    nsuid,
    requiresSubscription,
    sku,
    requiresCoupon,
    requiresLogin,
    platinumPoints,
    eshopDetails,
    prePurchase,
    prices,
    loadingPricing,
    productType,
    isSalableQty,
    releaseDate,
    releaseDateDisplay,
    soldOutPermanent,
    isPreorderable,
    variations,
    voucherNsuid,
    startShippingDate,
    shipDateDisplay,
    enableRetailCrawler,
    waitlist,
  } = product;

  const isLoading =
    loadingPricing || cart?.loading || cart?.updating || maintenance?.loading;
  const [showRedemptionCodeModal, setShowRedemptionCodeModal] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [selectedOptionsByTitle, setSelectedOptionsByTitle] = useState({});
  const analytics = useSearchAnalytics();
  const isMobileGame = nsuid === 'MOBILE';
  const isBundle = productType === 'BUNDLE';
  const isConfigurableProduct = productType === 'CONFIGURABLE';
  const isDigitalProduct = isProductDigital(product);

  // Campaign-specific properties
  const campaign = waitlist?.campaign;
  const invite = waitlist?.invite;

  const allItemsSelected = bundleItems?.every(
    ({ required, title }) => required && !!selectedOptionsByTitle[title]
  );

  useEffect(() => {
    if (isBundle) {
      const selectedOptions = bundleItems?.reduce(
        (selected, { options, title }) => {
          const defaultOption =
            options.length > 1
              ? options?.find(({ isDefault }) => isDefault)
              : options[0];
          return {
            ...selected,
            [title]: defaultOption?.id,
          };
        },
        {}
      );
      setSelectedOptionsByTitle(selectedOptions);
    }
  }, [isBundle, bundleItems]);

  const isPurchasable =
    (availability?.includes(text('Pre-order')) ||
      availability?.includes(text('Available now')) ||
      isSalableQty ||
      prePurchase) &&
    !isMobileGame;

  const { consentedGroups } = useCookies();

  const retailerSku = useMemo(() => {
    if (!enableRetailCrawler) return null;

    const hasConsented = consentedGroups?.has(ConsentGroups.PERFORMANCE);
    if (!hasConsented) return null;

    if (nsuid && variations?.length) {
      const physicalVariation = variations.find(
        (variation) => !variation.product?.nsuid
      );
      if (physicalVariation) return physicalVariation.product.sku;
    }

    return nsuid ? null : sku;
  }, [consentedGroups, nsuid, sku, variations, enableRetailCrawler]);

  const membershipActive = customer?.data?.membershipStatus.active;
  const addableQty = cart?.addableQty(product);
  const itemLimitReached = quantity >= addableQty;
  const soldOut =
    (!isSalableQty && !isMobileGame) ||
    (!!variations?.length &&
      variations.every(({ product }) => !product.isSalableQty));

  const isPrepurchasable = prePurchase && shipDateDisplay && !isSalableQty;

  const loginToPurchase =
    (requiresSubscription || platinumPoints || requiresLogin) &&
    !account.isLoggedIn;

  const membershipRequired = requiresSubscription && !membershipActive;

  const {
    buttonDisabled,
    promptLogin,
    disableQuantity,
    buttonCopy,
    helperText,
    boldHelperText,
  } = useMemo(() => {
    if (isMobileGame) return {};

    let buttonCopy = '',
      helperText = '',
      boldHelperText = false,
      promptLogin = false,
      disableQuantity = isDigitalProduct,
      buttonDisabled = false,
      isPhysicalMaintenance = maintenance?.store?.active;

    const voucherText = (
      <S.Link
        href="/store/products/nintendo-switch-game-vouchers/"
        locale={locale}
      >
        {text('Learn more about vouchers')}
      </S.Link>
    );
    const membershipText = (
      <S.Link href="/switch/online/">
        {text('Nintendo Switch Online members')}
      </S.Link>
    );

    if (requiresSubscription) {
      helperText = text('This item is only for paid {0}.', {
        args: [membershipText],
      });
    }

    // Set button states for campaign-enabled products where the user don't have an active invite
    if (campaign && invite?.status !== 'active') {
      helperText =
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam non tempus erat. Curabitur molestie a nisl sed suscipit. Suspendisse in augue odio. Donec sit amet quam orci. Donec a varius turpis, sed interdum eros. Fusce ac sollicitudin massa, quis dignissim nulla. Duis nec urna nulla. Morbi tristique mattis mauris sit amet tincidunt. Suspendisse aliquam tellus mauris, quis volutpat odio eleifend in. Ut id interdum ex, ac auctor massa. Cras commodo fermentum aliquet. Nulla sed magna ac eros tincidunt placerat. Integer at risus convallis, elementum sem quis, bibendum justo. Cras maximus fringilla auctor.';

      if (loginToPurchase) {
        buttonCopy = 'Sign in';
        promptLogin = true;
      } else if (campaign.allowRequests === false && !invite) {
        buttonCopy = 'Invite required';
        helperText = 'Attention: Invite required. ' + helperText;
        buttonDisabled = true;
      } else if (!invite) {
        buttonCopy = 'Get on Waitlist';
        helperText =
          'Get on the waitlist and we will let you know when you are up! ' +
          helperText;
      } else {
        buttonDisabled = true;
        if (invite.status === 'pending') {
          buttonCopy = 'Invite requested';
          helperText =
            "You're already on the waitlist! We will let you know when you are up! " +
            helperText;
        } else if (invite.status === 'redeemed') {
          buttonCopy = 'Success';
        } else {
          buttonCopy = 'Unavailable';
        }
      }
    } else if (isDigitalProduct) {
      if (maintenance?.eshop?.active) {
        boldHelperText = true;
        helperText = text(
          'Direct downloads are not possible during maintenance'
        );
        buttonDisabled = true;
        buttonCopy = 'Direct download';
      } else if (__typename === 'MarketingProduct') {
        buttonDisabled = true;
        buttonCopy = text('Releases {0}', {
          args: [
            releaseDateDisplay ||
              (releaseDate &&
                date(releaseDate, {
                  format: {
                    day: 'numeric',
                    month: 'numeric',
                    year: '2-digit',
                  },
                })),
          ],
        });
      } else if (isPrepurchasable) {
        buttonDisabled = true;
        disableQuantity = true;
        buttonCopy = text('Releases {0}', { args: [releaseDateDisplay] });
      } else if (loginToPurchase) {
        buttonCopy = 'Sign in to download';
        promptLogin = true;
      } else if (membershipRequired) {
        buttonDisabled = true;
        buttonCopy = 'Direct download';
      } else if (eshopDetails?.isPreordered) {
        buttonDisabled = true;
        buttonCopy = 'Already pre-ordered';
      } else if (eshopDetails?.isPurchased) {
        buttonDisabled = true;
        buttonCopy = 'Already downloaded';
      } else if (eshopDetails?.isPreorderable) {
        buttonCopy = 'Pre-order';
        if (voucherNsuid) {
          helperText = text(
            'Pre-order or redeem a Game Voucher to pre-load this game and start playing as soon as it’s released. {0}',
            { args: [voucherText] }
          );
        } else {
          helperText = text(
            `This lets you pre-load the game, so you'll be able to play as soon as the game is released.`
          );
        }
      } else if (!eshopDetails?.isPurchasable) {
        const releaseDateAsDate = new Date(releaseDate);
        buttonDisabled = true;
        if (releaseDateDisplay) {
          buttonCopy = text('Releases {0}', { args: [releaseDateDisplay] });
        } else if (Date.now() < releaseDateAsDate) {
          buttonCopy = text(`Releases {0}`, {
            args: [
              date(releaseDateAsDate, {
                format: {
                  day: 'numeric',
                  month: 'numeric',
                  year: '2-digit',
                },
              }),
            ],
          });
        } else {
          buttonCopy = 'Not available';
          helperText = 'This item is not available to download.';
        }
      } else if (prices?.minimum?.finalPrice == 0) {
        buttonCopy = 'Free download';
      } else if (voucherNsuid) {
        buttonCopy = 'Direct download';
        helperText = text(
          'This item will be sent to your system automatically after purchase or Nintendo Switch Game Voucher redemption. {0}',
          { args: [voucherText] }
        );
      } else {
        buttonCopy = 'Direct download';
        helperText =
          'This item will be sent to your system automatically after purchase.';
      }
    } else {
      // physical product
      // no salableQty, but do not want to show "Sold Out"
      if (isPrepurchasable) {
        buttonDisabled = true;
        disableQuantity = true;
        buttonCopy = text('Releases {0}', { args: [releaseDateDisplay] });
      } else if (soldOut) {
        buttonDisabled = true;
        disableQuantity = true;
        buttonCopy = 'Sold out';
        helperText = soldOutPermanent
          ? 'This item is no longer available.'
          : 'This item is currently unavailable. Please check back soon.';
      } else if (prePurchase) {
        buttonCopy = text('Pre-purchase');
        if (shipDateDisplay || startShippingDate) {
          const formattedShippingDate =
            startShippingDate &&
            date(startShippingDate, {
              format: {
                day: 'numeric',
                month: 'numeric',
                year: '2-digit',
              },
            });
          helperText = text(
            'This item will be shipped to your address. Ships {0}.',
            { args: [shipDateDisplay || formattedShippingDate], locale }
          );
        }
      } else if (releaseDateDisplay) {
        buttonDisabled = true;
        disableQuantity = true;
        buttonCopy = text('Releases {0}', { args: [releaseDateDisplay] });
      } else if (loginToPurchase) {
        buttonCopy = 'Sign in to add to cart';
        promptLogin = true;
      } else if (membershipRequired) {
        buttonDisabled = true;
        buttonCopy = 'Add to cart';
      } else if (Date.now() < new Date(releaseDate)) {
        buttonDisabled = true;
        buttonCopy = 'Currently unavailable';
      } else if (isConfigurableProduct) {
        buttonDisabled = true;
        disableQuantity = true;
        buttonCopy = 'Select a product';
      } else if (addableQty < 1) {
        buttonDisabled = true;
        buttonCopy = 'Item limit reached';
      } else if (isPreorderable) {
        buttonCopy = 'Pre-purchase';
      } else {
        buttonCopy = 'Add to cart';
        if (requiresCoupon) {
          boldHelperText = true;
          helperText =
            "You'll need a redemption code to add this item to your cart.";
        } else {
          helperText =
            'This item will be shipped to your address. ' + helperText;
        }
        buttonDisabled = isBundle && !allItemsSelected;
      }
      // maintenance maintains button text, but changes help text
      if (isPhysicalMaintenance) {
        boldHelperText = true;
        helperText = text('Adding to cart is not possible during maintenance');
        buttonDisabled = true;
      }
    }

    return {
      buttonCopy,
      boldHelperText,
      helperText,
      promptLogin,
      disableQuantity,
      buttonDisabled,
    };
  }, [
    __typename,
    isBundle,
    allItemsSelected,
    membershipRequired,
    loginToPurchase,
    eshopDetails,
    prices,
    isConfigurableProduct,
    soldOut,
    soldOutPermanent,
    addableQty,
    releaseDate,
    releaseDateDisplay,
    isPreorderable,
    isPrepurchasable,
    prePurchase,
    requiresSubscription,
    isMobileGame,
    isDigitalProduct,
    maintenance,
    text,
    voucherNsuid,
    requiresCoupon,
    date,
    locale,
    shipDateDisplay,
    startShippingDate,
    campaign,
    invite,
    isPrepurchasable,
  ]);

  const displayPrice = useMemo(
    () => ({
      regPrice:
        Boolean(releaseDateDisplay) && !prePurchase
          ? null
          : price(prices?.minimum?.regularPrice, { formatZero: true }),
      salePrice: prices?.minimum?.discounted
        ? price(prices?.minimum?.finalPrice)
        : '',
    }),
    [releaseDateDisplay, prePurchase, prices, price]
  );

  const handleDirectDownloadClick = useCallback(() => {
    if (promptLogin) {
      account.promptLogin();
    } else {
      // Evict their eshopDetails in case they purchase the product and return
      apolloClient.cache.evict({
        id: apolloClient.cache.identify(product),
        fieldName: 'eshopDetails',
        args: { personalized: true },
        broadcast: false,
      });
      const ctaLabel = typeof buttonCopy === 'string' ? buttonCopy : 'CTA';

      /** Ncom analytics */
      trackAddToCart(product);

      /** Search analytics */
      analytics.trackEvent(
        {
          eventName: `PDP: ${ctaLabel} Clicked`,
          objectId: sku,
          isConversion: true,
        },
        locale
      );
    }
  }, [
    promptLogin,
    buttonCopy,
    locale,
    sku,
    analytics,
    trackAddToCart,
    account,
    apolloClient.cache,
    product,
  ]);

  const handleAddToCart = useCallback(
    (couponCode) => {
      if (promptLogin) {
        account.promptLogin();
      } else if (product.requiresCoupon && !couponCode) {
        setShowRedemptionCodeModal(true);
      } else {
        /** Ncom analytics */
        trackAddToCart(product);

        /** Search analytics */
        analytics.addToCart(sku, {
          eventName: `PDP: Add to Cart Clicked`,
          locale,
        });

        return cart.addProduct(product, {
          couponCode: couponCode?.trim(),
          selectedOptions: Object.values(selectedOptionsByTitle),
          quantity,
        });
      }
    },
    [
      sku,
      promptLogin,
      analytics,
      account,
      cart,
      product,
      quantity,
      locale,
      trackAddToCart,
      selectedOptionsByTitle,
    ]
  );

  const value = useMemo(
    () => ({
      state: {
        isLoading,
        quantity,
        isMobileGame,
        isDigitalProduct,
        isPurchasable,
        isConfigurableProduct,
        retailerSku,
        membershipRequired,
        addableQty,
        itemLimitReached,
        soldOut,
        loginToPurchase,
        buttonDisabled,
        promptLogin,
        disableQuantity,
        buttonCopy,
        helperText,
        boldHelperText,
        displayPrice,
        showRedemptionCodeModal,
        selectedOptionsByTitle,
      },
      actions: {
        setQuantity,
        setSelectedOptionsByTitle,
        handleDirectDownloadClick,
        handleAddToCart,
        setShowRedemptionCodeModal,
      },
    }),
    [
      isLoading,
      quantity,
      isMobileGame,
      isDigitalProduct,
      isPurchasable,
      isConfigurableProduct,
      retailerSku,
      membershipRequired,
      addableQty,
      itemLimitReached,
      soldOut,
      loginToPurchase,
      buttonDisabled,
      promptLogin,
      disableQuantity,
      buttonCopy,
      helperText,
      boldHelperText,
      displayPrice,
      setQuantity,
      handleDirectDownloadClick,
      handleAddToCart,
      showRedemptionCodeModal,
      setShowRedemptionCodeModal,
      selectedOptionsByTitle,
      setSelectedOptionsByTitle,
    ]
  );

  return value;
};

export default usePurchaseOptions;
